<template>
	<div class="User">
		
		<div class="Top">
			<div class="Right">
				<span style="margin-left: 10px;">
					<label>状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="展示中"></el-option>
						<el-option :value="10" label="隐藏中"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>订单编号</label>
					<el-input size="mini" v-model="Search.OrderId"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>店铺名</label>
					<el-input size="mini" v-model="Search.ShopName"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>用户手机</label>
					<el-input size="mini" v-model="Search.UserMobile"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>商品编号</label>
					<el-input size="mini" v-model="Search.GoodsId"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">评论内容</div>
				<div class="C2">状态</div>
				<div class="C3">打分</div>
				<div class="C4">创建时间</div>
				<div class="C5">编号</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in NewsList" :key="item.Id">
				<li>
					<div class="C1">
						<template v-for="(goods,goodsI) in GoodsList">
							<a style="display: block;color: rgba(0,0,0,0.5);" v-if="goods.Id == item.GoodsId" :href="'https://www.wojiasx.com/#/goods/detail/'+item.GoodsId" target="_blank" :key="goodsI">[商品]{{goods.Title}}</a>
						</template>
						
					{{item.Content}}
					</div>
					<div class="C2">{{item.StatusName}}</div>
					<div class="C3">{{item.Score}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="StatusUpdate(item.Id,10)" v-if="item.Status == 50" round>隐藏</el-button>
						<el-button size="mini" @click="StatusUpdate(item.Id,50)" v-if="item.Status == 10" round>显示</el-button>
						<el-button size="mini" @click="DelCheck(item.Id)" round>删除</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'GoodsComment',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Id:'',
				  OrderId:'',
				  OrderDetailId:'',
				  ShopName:'',
				  UserMobile:'',
				  GoodsId:'',
				  Status:'',
			  },
			  NewsList:[],
			  GoodsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  DelCheck(_id){
			  this.$confirm('是否确认删除，确认后将被永久删除?', '提示', {
			  				confirmButtonText: '确定',
			  				cancelButtonText: '取消',
			  				type: 'warning'
			  				}).then(() => {
			  					this.Del(_id)
			  				})
		  },
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
			  
		  		let data = {
		  			Service:'Ad',
		  			Class: 'GoodsComment',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  			Id:this.Search.Id,
		  			OrderId:this.Search.OrderId,
		  			OrderDetailId:this.Search.OrderDetailId,
		  			ShopName:this.Search.ShopName,
					UserMobile:this.Search.UserMobile,
					GoodsId:this.Search.GoodsId,
					Status:this.Search.Status,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.NewsList = res.Data.GoodsCommentList
					this.Page = res.Data.CurrentPage
					
					let ids = []
					res.Data.GoodsCommentList.forEach(item => {
						ids.push(item.GoodsId)
					})
					this.GetGoodsList(ids)
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  },
		  GetGoodsList(_ids){
			  this.GoodsList = []
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Goods',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:_ids.length,
		  			Ids:JSON.stringify(_ids),
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.GoodsList = res.Data.GoodsList
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Ad',
		  			Action: 'Count',
		  			Id:this.Search.Id,
		  			OrderId:this.Search.OrderId,
		  			OrderDetailId:this.Search.OrderDetailId,
		  			ShopName:this.Search.ShopName,
		  			UserMobile:this.Search.UserMobile,
		  			GoodsId:this.Search.GoodsId,
		  			Status:this.Search.Status,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
		  StatusUpdate(_id,_status){
		  		let data = {
		  			Service:'Ad',
		  			Class: 'GoodsComment',
		  			Action: 'Update',
		  			Id:_id,
		  			Status:_status,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			
					if(_status == 10){
						this.$message.success('评论已隐藏，商城不可见')
					}else if(_status == 50){
						this.$message.success('评论已展示，商城可见')
					}
		  			this.GetList(this.Search.Page)
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
		  Del(_id){
		  		let data = {
		  			Service:'Ad',
		  			Class: 'GoodsComment',
		  			Action: 'Update',
		  			Id:_id
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			
		  			this.$message.success(res.Msg)
		  			this.GetList(this.Search.Page)
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .Top .Right label{
	display: block;
	margin-bottom: 5px;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C5{
	width: 250px;
	color: rgba(0,0,0,0.4);
}
.C1{
	padding-left: 10px;
}

.C6{
	width: 130px;
	text-align: right;
	padding-right: 10px;
}
.C1{
	flex: 1;
}
.C2,.C3{
	width: 80px;
}
.C4{
	width: 100px;
	color: rgba(0,0,0,0.4);
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
